/** * Created For: TimerBtn 倒计时组件 * Author: Yeoman * Date: 2020/10/1 19:21
*/
<template>
  <el-button
    :class="['sms-btn', time > 0 ? 'sending' : '']"
    :disabled='time > 0'
    :loading='loading'
    @click='runHandle()'
    plain
  >{{ smsText }}
  </el-button
  >
</template>

<script>
export default {
  name: 'TimerBtn',
  components: {},
  props: {
    // 倒计时的秒数
    second: {
      type: Number,
      default: 60
    },
    tel:{
      type: String,
    },
  },
  data() {
    return {
      time: 0,
      loading: false
    }
  },
  computed: {
    smsText() {
      return this.time > 0 ? this.time + ' s' : '获取验证码'
    }
  },
  mounted() {
  },
  methods: {
    runHandle() {
      if(this.tel==undefined){
         this.$message.error("请先填写手机号!");
         return false
      } 
      this.loading = true
      this.$emit('run')
    },
    start() {
      this.loading = false
      this.time = this.second
      this.timer()
    },
    timer() {
      if (this.time > 0) {
        this.time--
        setTimeout(this.timer, 1000)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.sms-btn {
  min-width: 106px;
  height: 36px;
  padding: 0 20px;
  line-height: normal;
  border: 1px solid #2F88FF;
  color: #2F88FF;
  font-size: 12px;
  border-radius: 18px;

  &.is-disabled,
  &.is-disabled:hover {
    border-color: #dee3e6;
    color: #999;
  }
}
.el-button.is-plain:hover{
    background:none;
}
.el-button{
    margin: -8px -25px;
    background: none; 
    border: 0px solid #DCDFE6; 
    font-size: 14px;
    font-weight: 400;
    color: #2F88FF;
}
</style>
