

const allCityCodes = [
  ["110000","110100"],
  ["120000","120100"],
  ["130000","130100"],
  ["130000","130200"],
  ["130000","130300"],
  ["130000","130400"],
  ["130000","130500"],
  ["130000","130600"],
  ["130000","130700"],
  ["130000","130800"],
  ["130000","130900"],
  ["130000","131000"],
  ["130000","131100"],
  ["140000","140100"],
  ["140000","140200"],
  ["140000","140300"],
  ["140000","140400"],
  ["140000","140500"],
  ["140000","140600"],
  ["140000","140700"],
  ["140000","140800"],
  ["140000","140900"],
  ["140000","141000"],
  ["140000","141100"],
  ["150000","150100"],
  ["150000","150200"],
  ["150000","150300"],
  ["150000","150400"],
  ["150000","150500"],
  ["150000","150600"],
  ["150000","150700"],
  ["150000","150800"],
  ["150000","150900"],
  ["150000","152200"],
  ["150000","152500"],
  ["150000","152900"],
  ["210000","210100"],
  ["210000","210200"],
  ["210000","210300"],
  ["210000","210400"],
  ["210000","210500"],
  ["210000","210600"],
  ["210000","210700"],
  ["210000","210800"],
  ["210000","210900"],
  ["210000","211000"],
  ["210000","211100"],
  ["210000","211200"],
  ["210000","211300"],
  ["210000","211400"],
  ["220000","220100"],
  ["220000","220200"],
  ["220000","220300"],
  ["220000","220400"],
  ["220000","220500"],
  ["220000","220600"],
  ["220000","220700"],
  ["220000","220800"],
  ["220000","222400"],
  ["230000","230100"],
  ["230000","230200"],
  ["230000","230300"],
  ["230000","230400"],
  ["230000","230500"],
  ["230000","230600"],
  ["230000","230700"],
  ["230000","230800"],
  ["230000","230900"],
  ["230000","231000"],
  ["230000","231100"],
  ["230000","231200"],
  ["230000","232700"],
  ["310000","310100"],
  ["320000","320100"],
  ["320000","320200"],
  ["320000","320300"],
  ["320000","320400"],
  ["320000","320500"],
  ["320000","320600"],
  ["320000","320700"],
  ["320000","320800"],
  ["320000","320900"],
  ["320000","321000"],
  ["320000","321100"],
  ["320000","321200"],
  ["320000","321300"],
  ["330000","330100"],
  ["330000","330200"],
  ["330000","330300"],
  ["330000","330400"],
  ["330000","330500"],
  ["330000","330600"],
  ["330000","330700"],
  ["330000","330800"],
  ["330000","330900"],
  ["330000","331000"],
  ["330000","331100"],
  ["340000","340100"],
  ["340000","340200"],
  ["340000","340300"],
  ["340000","340400"],
  ["340000","340500"],
  ["340000","340600"],
  ["340000","340700"],
  ["340000","340800"],
  ["340000","341000"],
  ["340000","341100"],
  ["340000","341200"],
  ["340000","341300"],
  ["340000","341500"],
  ["340000","341600"],
  ["340000","341700"],
  ["340000","341800"],
  ["350000","350100"],
  ["350000","350200"],
  ["350000","350300"],
  ["350000","350400"],
  ["350000","350500"],
  ["350000","350600"],
  ["350000","350700"],
  ["350000","350800"],
  ["350000","350900"],
  ["360000","360100"],
  ["360000","360200"],
  ["360000","360300"],
  ["360000","360400"],
  ["360000","360500"],
  ["360000","360600"],
  ["360000","360700"],
  ["360000","360800"],
  ["360000","360900"],
  ["360000","361000"],
  ["360000","361100"],
  ["370000","370100"],
  ["370000","370200"],
  ["370000","370300"],
  ["370000","370400"],
  ["370000","370500"],
  ["370000","370600"],
  ["370000","370700"],
  ["370000","370800"],
  ["370000","370900"],
  ["370000","371000"],
  ["370000","371100"],
  ["370000","371300"],
  ["370000","371400"],
  ["370000","371500"],
  ["370000","371600"],
  ["370000","371700"],
  ["410000","410100"],
  ["410000","410200"],
  ["410000","410300"],
  ["410000","410400"],
  ["410000","410500"],
  ["410000","410600"],
  ["410000","410700"],
  ["410000","410800"],
  ["410000","410900"],
  ["410000","411000"],
  ["410000","411100"],
  ["410000","411200"],
  ["410000","411300"],
  ["410000","411400"],
  ["410000","411500"],
  ["410000","411600"],
  ["410000","411700"],
  ["410000","419000"],
  ["420000","420100"],
  ["420000","420200"],
  ["420000","420300"],
  ["420000","420500"],
  ["420000","420600"],
  ["420000","420700"],
  ["420000","420800"],
  ["420000","420900"],
  ["420000","421000"],
  ["420000","421100"],
  ["420000","421200"],
  ["420000","421300"],
  ["420000","422800"],
  ["420000","429000"],
  ["430000","430100"],
  ["430000","430200"],
  ["430000","430300"],
  ["430000","430400"],
  ["430000","430500"],
  ["430000","430600"],
  ["430000","430700"],
  ["430000","430800"],
  ["430000","430900"],
  ["430000","431000"],
  ["430000","431100"],
  ["430000","431200"],
  ["430000","431300"],
  ["430000","433100"],
  ["440000","440100"],
  ["440000","440200"],
  ["440000","440300"],
  ["440000","440400"],
  ["440000","440500"],
  ["440000","440600"],
  ["440000","440700"],
  ["440000","440800"],
  ["440000","440900"],
  ["440000","441200"],
  ["440000","441300"],
  ["440000","441400"],
  ["440000","441500"],
  ["440000","441600"],
  ["440000","441700"],
  ["440000","441800"],
  ["440000","441900"],
  ["440000","442000"],
  ["440000","445100"],
  ["440000","445200"],
  ["440000","445300"],
  ["450000","450100"],
  ["450000","450200"],
  ["450000","450300"],
  ["450000","450400"],
  ["450000","450500"],
  ["450000","450600"],
  ["450000","450700"],
  ["450000","450800"],
  ["450000","450900"],
  ["450000","451000"],
  ["450000","451100"],
  ["450000","451200"],
  ["450000","451300"],
  ["450000","451400"],
  ["460000","460100"],
  ["460000","460200"],
  ["460000","460300"],
  ["460000","460400"],
  ["460000","469000"],
  ["500000","500100"],
  ["510000","510100"],
  ["510000","510300"],
  ["510000","510400"],
  ["510000","510500"],
  ["510000","510600"],
  ["510000","510700"],
  ["510000","510800"],
  ["510000","510900"],
  ["510000","511000"],
  ["510000","511100"],
  ["510000","511300"],
  ["510000","511400"],
  ["510000","511500"],
  ["510000","511600"],
  ["510000","511700"],
  ["510000","511800"],
  ["510000","511900"],
  ["510000","512000"],
  ["510000","513200"],
  ["510000","513300"],
  ["510000","513400"],
  ["520000","520100"],
  ["520000","520200"],
  ["520000","520300"],
  ["520000","520400"],
  ["520000","520500"],
  ["520000","520600"],
  ["520000","522300"],
  ["520000","522600"],
  ["520000","522700"],
  ["530000","530100"],
  ["530000","530300"],
  ["530000","530400"],
  ["530000","530500"],
  ["530000","530600"],
  ["530000","530700"],
  ["530000","530800"],
  ["530000","530900"],
  ["530000","532300"],
  ["530000","532500"],
  ["530000","532600"],
  ["530000","532800"],
  ["530000","532900"],
  ["530000","533100"],
  ["530000","533300"],
  ["530000","533400"],
  ["540000","540100"],
  ["540000","540200"],
  ["540000","540300"],
  ["540000","540400"],
  ["540000","540500"],
  ["540000","540600"],
  ["540000","542500"],
  ["610000","610100"],
  ["610000","610200"],
  ["610000","610300"],
  ["610000","610400"],
  ["610000","610500"],
  ["610000","610600"],
  ["610000","610700"],
  ["610000","610800"],
  ["610000","610900"],
  ["610000","611000"],
  ["620000","620100"],
  ["620000","620200"],
  ["620000","620300"],
  ["620000","620400"],
  ["620000","620500"],
  ["620000","620600"],
  ["620000","620700"],
  ["620000","620800"],
  ["620000","620900"],
  ["620000","621000"],
  ["620000","621100"],
  ["620000","621200"],
  ["620000","622900"],
  ["620000","623000"],
  ["630000","630100"],
  ["630000","630200"],
  ["630000","632200"],
  ["630000","632300"],
  ["630000","632500"],
  ["630000","632600"],
  ["630000","632700"],
  ["630000","632800"],
  ["640000","640100"],
  ["640000","640200"],
  ["640000","640300"],
  ["640000","640400"],
  ["640000","640500"],
  ["650000","650100"],
  ["650000","650200"],
  ["650000","650400"],
  ["650000","650500"],
  ["650000","652300"],
  ["650000","652700"],
  ["650000","652800"],
  ["650000","652900"],
  ["650000","653000"],
  ["650000","653100"],
  ["650000","653200"],
  ["650000","654000"],
  ["650000","654200"],
  ["650000","654300"],
  ["650000","659000"],
  ["710000","710100"],
  ["710000","710200"],
  ["710000","710300"],
  ["710000","710400"],
  ["710000","710500"],
  ["710000","710600"],
  ["710000","710700"], 

  ["810000","810001"],
  ["810000","810002"],
  ["810000","810003"],
  ["810000","810004"],
  ["810000","810005"],
  ["810000","810006"],
  ["810000","810007"],
  ["810000","810008"],
  ["810000","810009"],
  ["810000","810010"],
  ["810000","810011"],
  ["810000","810012"],
  ["810000","810013"],
  ["810000","810014"],
  ["810000","810015"],
  ["810000","810016"],
  ["810000","810017"],
  ["810000","810018"],

  ["820000","820001"],
  ["820000","820002"],
  ["820000","820003"],
  ["820000","820004"],
  ["820000","820005"],
  ["820000","820006"],
  ["820000","820007"],
  ["820000","820008"]

]
export default allCityCodes;
